<template>
  <v-container
    class="px-lg-10 mx-0 background-color: white"
    style="border: 1px solid grey"
  >
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="8"
      >
        <v-spacer />
      </v-col>
      <v-col
        class="px-8 py-8 text-right"
        cols="12"
        sm="6"
        md="4"
      >
        <v-btn
          color="orange"
          dark
          class="mb-2"
          @click="cancelButtonClick"
        >
          KEMBALI
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Nama
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ fullName }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Status
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.status_string }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Email
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.email }}
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                v-if="dataCustomer.email_verified"
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                mdi-check-circle-outline
              </v-icon>
              <v-icon
                v-else
                color="error"
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{ hoverTextEmail }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        No HP
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="4"
      >
        {{ dataCustomer.phone }}
        <!-- <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon
                v-if="dataCustomer.email_verified"
                color="success"
                v-bind="attrs"
                v-on="on"
              >
                mdi-check-circle-outline
              </v-icon>
              <v-icon
                v-else
                color="error"
              >
                mdi-close-circle
              </v-icon>
            </v-btn>
          </template>
          <span>{{ hoverTextEmail }}</span>
        </v-tooltip> -->
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        No KTP
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-text-field
          ref="ktpNumberField"
          v-model="dataCustomer.ktp_num"
          counter
          color="purple"
          :hint="noKTPHint"
          maxlength="16"
          persistent-hint
          outlined
          dense
          :error-messages="noKTPErrMessage"
          @input="eventInputnoKTP"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        Data KTP
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-img
          max-width="350"
          :src="dataCustomer.ktp_img_path.data.file_path"
          contain
          style="
            cursor: pointer;
          "
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <v-img
          max-width="350"
          :src="dataCustomer.ktp_selfie_img_path.data.file_path"
          contain
          style="
            cursor: pointer;
          "
        />
      </v-col>
    </v-row>
    <v-row
      class="py-8"
    >
      <v-col
        cols="12"
        sm="6"
        md="2"
      >
        <v-spacer />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <v-btn
          color="primary"
          @click="verifyButtonClick(1)"
        >
          TELAH SESUAI
        </v-btn>
        <v-btn
          color="red"
          @click="verifyButtonClick(0)"
        >
          BELUM SESUAI
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import axios from 'axios'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dataCustomer: {
        hash: '',
        type: '',
        avatar: '',
        status: '1',
        status_string: 'Aktif',
        fname: '',
        lname: '',
        phone: '',
        email: '',
        email_verified: '',
        ktp_verified: '',
        ktp_num: '',
        ktp_img_path: {
          data: {
            file_path: '',
          },
        },
        ktp_selfie_img_path: {
          data: {
            file_path: '',
          },
        },
      },
      noKTPHint: 'Masukkan nomor KTP sesuai gambar yang diupload',
      noKTPErrMessage: '',
    }),

    computed: {
      fullName () {
        return this.dataCustomer.fname + ' ' + this.dataCustomer.lname
      },
      hoverTextEmail () {
        let text = ''
        this.dataCustomer.email_verified ? text = 'Email telah diverifikasi' : text = 'Email belum diverifikasi'
        return text
      },
    },

    watch: {
    },

    created () {
      this.initialize(this.$route.params.id)
    },

    methods: {
      initialize (id) {
        const vm = this

        const requestBody = {
          cust_hash: id,
        }

        axios.post('/t/customer/verification/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.dataCustomer = res.data.data.detail
          }
        }).catch((e) => {
        })
      },

      verifyButtonClick (status) {
        if (status !== 0 && !this.validateField()) return

        const requestBody = {
          cust_hash: this.dataCustomer.hash,
          submit_status: status,
          no_ktp: this.dataCustomer.ktp_num,
        }

        axios.post('/t/customer/verification/modify', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success(res.data.message)
            setTimeout(() => {
              this.$router.push({ name: 'CustomersVerification' })
            }, 2500)
          }
        }).catch((e) => {
          e.response.data.error_type === 'array' ? this.$toast.error(e.response.data.error.reason) : this.$toast.error(e.response.data.error)
        })
      },

      validateField () {
        if (this.dataCustomer.ktp_num === null) {
          this.noKTPErrMessage = 'No KTP harus diisi'
          this.$refs.ktpNumberField.focus()
          return false
        }

        const val = this.dataCustomer.ktp_num.trim().length

        if (val === 0) {
          this.noKTPErrMessage = 'No KTP harus diisi'
          this.$refs.ktpNumberField.focus()
          return false
        }
        if (val < 16) {
          this.noKTPErrMessage = 'Nomor KTP terdiri dari 16 digit angka'
          this.$refs.ktpNumberField.focus()
          return false
        }

        return true
      },

      eventInputnoKTP (val) {
        this.noKTPErrMessage = ''
      },

      cancelButtonClick () {
        this.$router.push({ name: 'CustomersVerification' })
      },
    },
  }
</script>
